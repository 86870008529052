import { WebtrekkSmartPixelReact } from '@webtrekk-smart-pixel/react';
import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ContentContainer from './components/global/ContentContainer';
import Footer from './components/global/Footer';
import Header from './components/global/Header';
import ProtectedComponent from './components/global/ProtectedComponent';
import ProtectedRoute from './components/global/ProtectedRoute';
import Alerts from './containers/Alerts';
import Data from './containers/Data';
import GlobalDialog from './containers/GlobalDialog';
import Home from './pages/Home';
import Lesson from './pages/Lesson';
import Login from './pages/Login';
import Privacy from './pages/Privacy';
import Progress from './pages/Progress';
import ProgressLessons from './pages/ProgressLessons';
import ProgressUnits from './pages/ProgressUnits';
import ProgressWeek from './pages/ProgressWeek';
import Unit from './pages/Unit';
import { logout } from './redux/actions/user';
import { ALERT } from './shared/constants/alert';
import { UnitsList } from './pages/UnitsList';
import { UnitEdit } from './pages/UnitEdit';
import { LessonEdit } from './pages/LessonEdit';
import { UsersList } from './pages/UsersList';
import { Spinner } from './components/spinner/Spinner';

class App extends React.Component {

  
  componentDidMount() {
    let hostname = window.location.hostname;
    let trackId  = hostname === 'aussprachetraining.goethe.de'?'382202390743064':'549323177212540';
    WebtrekkSmartPixelReact.init({
      trackId,
      trackDomain:'data.goethe.de'
    });    
  }

  logout = () => {
    this.props.logout();
    this.props.history.push('/login');
  }

  render() {
    return (
      <Router>
        <Data/>
        <GlobalDialog/>
        <Spinner/>
        <div className='global-alerts'>
          <Alerts context={ALERT.DEFAULT_CONTEXT}/>
        </div>
        <Header logout={this.props.logout}/>
        <ContentContainer>
          <Switch>
            <ProtectedRoute path='/' exact={true} component={Home}/>
            <ProtectedRoute path='/unit/:id' component={Unit}/>
            <ProtectedRoute path='/lesson/:id' component={Lesson}/>
            <ProtectedRoute path='/progress/' exact={true} component={Progress}/>
            <ProtectedRoute path='/progress/units' exact={true} component={ProgressUnits}/>
            <ProtectedRoute path='/progress/lessons' exact={true} component={ProgressLessons}/>
            <ProtectedRoute path='/progress/week' exact={true} component={ProgressWeek}/>
            <ProtectedRoute path='/editor/units' exact={true} component={UnitsList}/>
            <ProtectedRoute path='/editor/units/:id' exact={true} component={UnitEdit}/>
            <ProtectedRoute path='/editor/:unitId/lesson/:lessonId' exact={true} component={LessonEdit}/>
            <ProtectedRoute path='/editor/users' exact={true} component={UsersList}/>
            <Route path='/privacy' exact={true} component={Privacy}/>
            <Route  path="/login"component={Login}/>        
          </Switch>
        </ContentContainer>
        <ProtectedComponent component={Footer}  logout={this.props.logout}/>
      </Router>  
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { logout, },
    dispatch,
  );
}


function mapStateToProps(state) {
  return {  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);


