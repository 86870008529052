import React, { useState } from "react";

import {
  Navbar,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Container,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

import { withNamespaces } from "react-i18next";

import { useHistory } from "react-router-dom";

import i18n from "../../i18n";
import { DIALOGS } from "../../containers/GlobalDialog";
import { getSupportedLanguage } from "../../utils/translate";
import { globalDialog } from "../../redux/actions/globalDialog";
import { logout } from "../../redux/actions/user";
import contactImage from "../../shared/images/contact.svg";
import documentLockedImage from "../../shared/images/document-locked.svg";
import documentImage from "../../shared/images/document.svg";
import globeImage from "../../shared/images/globe.svg";
import brandLogo from "../../shared/images/logo.svg";
import logoutImage from "../../shared/images/logout.svg";
import settingsImage from "../../shared/images/settings.svg";
import closeIcon from "../../shared/images/new/close.svg";
import contactIcon from "../../shared/images/new/contact.svg";
import documentIcon from "../../shared/images/new/document.svg";
import globeIcon from "../../shared/images/new/globe.svg";
import burgerIcon from "../../shared/images/new/hamburger.svg";
import logoutIcon from "../../shared/images/new/logout.svg";
import privacySettingsIcon from "../../shared/images/new/privacy-settings.svg";
import privacyIcon from "../../shared/images/new/privacy.svg";
import useScreenWidth from "../../hooks/layout/useScreenWidth";
import { BREAKPOINT_MOBILE_LAYOUT } from "../../shared/constants/theme";

import "./Header.scss";

/*global UC_UI*/
let contactUrls = {
  de: "https://www.goethe.de/de/spr/ueb/ast/knt.html",
  en: "https://www.goethe.de/en/spr/ueb/ast/knt.html",
  vi: "https://www.goethe.de/en/spr/ueb/ast/knt.html",
};

function Header(props) {
  const authenticatedUser = useSelector((state) => state.authenticatedUser);
  const anonymousUser = useSelector((state) => state.anonymousUser);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const screenWidth = useScreenWidth();
  const dispatch = useDispatch();

  const history = useHistory();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const openUrl = (path) => {
    let locale = props.lng == "de" ? "de" : "en";
    window.open(`https://www.goethe.de/${locale}${path}`, "_blank");
  };
  const openContact = () => {
    window.open(contactUrls[props.lng], "_blank");
  };

  let onToggle = () => {
    setMenuIsOpen(!menuIsOpen);
  };
  const lng = getSupportedLanguage(props.lng);

  const onLogout = () => {
    props.logout();
    onToggle();
  };

  const onPrivacyClick = () => {
    history.push("/privacy");
    onToggle();
  };

  const onPrivacySettingsClick = () => {
    UC_UI.showSecondLayer();
    onToggle();
  };

  return (
    <>
      <Modal isOpen={menuIsOpen} toggle={onToggle} className="header-menu">
        <ModalBody>
          <Row className="disabled row-container">
            <Col>
              <p className="main-title">{props.t("loginFooterTitle")}</p>
            </Col>
            <Col className="flex-grow-0">
              <Media
                object
                src={closeIcon}
                className="close-icon"
                onClick={onToggle}
              />
            </Col>
          </Row>

          <Row className="disabled row-container small-gap">
            <Col className="left-container">
              <Media src={globeIcon} className="icon" />
            </Col>
            <Col>
              <p className="title">{props.t("headerMenuAppLanguage")}</p>
            </Col>
          </Row>

          <Row
            className="row-container small-gap"
            onClick={() => changeLanguage("de")}
          >
            <Col className="left-container"></Col>
            <Col>
              <p className={`subtitle ${lng == "de" ? "active" : ""}`}>
                {props.t("userProfileLanguageDe")}
              </p>
            </Col>
          </Row>

          <Row
            className="row-container small-gap"
            onClick={() => changeLanguage("en")}
          >
            <Col className="left-container"></Col>
            <Col>
              <p className={`subtitle ${lng == "en" ? "active" : ""}`}>
                {props.t("userProfileLanguageEn")}
              </p>
            </Col>
          </Row>

          <Row className="row-container small-gap" onClick={() => changeLanguage("vi")}>
            <Col className="left-container"></Col>
            <Col>
              <p className={`subtitle ${lng == "vi" ? "active" : ""}`}>
                {props.t("userProfileLanguageVi")}
              </p>
            </Col>
          </Row>


          <Row className="row-container" onClick={() => openUrl("/imp.html")}>
            <Col className="left-container">
              <Media src={documentIcon} className="icon" />
            </Col>
            <Col>
              <p className="title">{props.t("headerMenuImprint")}</p>
            </Col>
          </Row>

          <Row className="row-container" onClick={onPrivacyClick}>
            <Col className="left-container">
              <Media src={privacyIcon} className="icon" />
            </Col>
            <Col>
              <p className="title">{props.t("headerMenuPrivacy")}</p>
            </Col>
          </Row>

          <Row className="row-container" onClick={onPrivacySettingsClick}>
            <Col className="left-container">
              <Media src={privacySettingsIcon} className="icon" />
            </Col>
            <Col>
              <p className="title">{props.t("headerMenuPrivacySettings")}</p>
            </Col>
          </Row>

          <Row className="row-container" onClick={() => openContact()}>
            <Col className="left-container">
              <Media src={contactIcon} className="icon" />
            </Col>
            <Col>
              <p className="title">{props.t("headerMenuContact")}</p>
            </Col>
          </Row>

          {(authenticatedUser || anonymousUser) && (
            <Row className="row-container" onClick={onLogout}>
              <Col className="left-container">
                <Media src={logoutIcon} className="icon" />
              </Col>
              <Col>
                <p className="title">
                  {anonymousUser
                    ? props.t("headerLogoutAnon")
                    : props.t("headerLogout")}
                </p>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      {screenWidth <= BREAKPOINT_MOBILE_LAYOUT
      ?
      <Navbar className="fixed-top header">
        <Col>
          <Row className="content-container">
            <Col>
              <p className="title">GOETHE-INSTITUT</p>
            </Col>
            <Col className="menu-icon-container">
              <Media
                src={burgerIcon}
                className="menu-icon"
                onClick={onToggle}
              />
            </Col>
          </Row>
        </Col>
      </Navbar>
      :
      <Navbar className="fixed-top header">
          <Col
            lg={{
              offset: 2,
              size: 8,
            }}
          >
            <Row className="content-container">
              <Col>
                <Media object src={brandLogo} className="icon" />
              </Col>
              <Col className="menu-icon-container">
                <Media
                  src={burgerIcon}
                  className="menu-icon"
                  onClick={onToggle}
                />
              </Col>
            </Row>
          </Col>
      </Navbar>
      }
    </>
  );
}

export default withNamespaces()(Header);
